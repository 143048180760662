.supportSignupContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex: 1;
}

.seperator {
  display: flex;
  align-items: center;
  height: 100%;
  font-family: ProximaNova;
  font-weight: 700;
  font-size: 18px;
  padding: 0 16px;
  text-transform: uppercase;
}

.content {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
}

.header {
  display: flex;
  font-weight: 700;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  margin-bottom: 32px;
  flex-wrap: wrap;
  color: var(--color-primary);
}

.signup {
  margin-right: 16px;
}

.support {
  margin-left: 16px;
}

.body {
  font-size: 14px;
  overflow-wrap: anywhere;
  color: var(--color-primary);
}

.action {
  color: white;
  width: fit-content;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  text-decoration: none;
  margin: 32px 0;
  padding: 10px 16px;
  background-color: var(--color-primary);
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.149284);
  border-radius: 3px;
}

.logo {
  max-height: 40px;
  margin: 16px 0;
}
