.channel-select-dropdown {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.channel-select-dropdown .channel-select {
  margin: 0 10px;
  min-width: 150px;
  padding: 2px;
}
