.header {
  padding: 11px 18px;
  color: #ffffff;
  font-family: ProximaNova;
  font-size: 16px;
  line-height: 19px;
  background-color: var(--color-primary);
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  min-height: 2em;
  align-items: center;
}

.content {
  padding: 20px 30px;
}

.panel {
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}
