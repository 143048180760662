.top-div-container .top-header {
  display: grid;
  padding: 15px 15px 15px 15px;
  align-items: center;
  grid-template-columns: 8fr 4fr;
}

.top-div-container .text-header {
  color: var(--color-topbar-text);
  font-size: 23px;
  font-weight: bolder;
  line-height: 30px;
}

.fs-13 {
  font-size: 13px;
}
.fw-700 {
  font-weight: 700;
}
.right-box {
  /* display: grid;
  grid-template-columns: 4fr 4fr 4fr; */
  display: flex;
  justify-content: flex-end;
}
.role-box {
  width: 100%;
  margin-right: 1em;
}
.question-box {
  width: 40px;
  margin-right: 1em;
}
.profile-box {
  width: 85%;
  z-index: 100;
}
.store-icon {
  margin-left: 15px;
  width: 35px;
}

.profile-detail {
  text-align: center;
  background-color: white;
  border-radius: 5px;
  display: flex;
  height: 45px;
  align-items: center;
}

.profile-ellipsis {
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.top-div-container .top-header .left-content .title {
  font-size: 23px;
  font-weight: bolder;
  line-height: 30px;
}

.topbar-bg {
  background-color: var(--color-topbar-bg);
}

.prod-bg {
  background-color: var(--color-prod);
}

.sandbox-bg {
  background-color: var(--color-sandbox);
}

.top-div-container.top-header .right-content {
  width: 17%;
  box-shadow: 10px 5px 14px -2px rgba(0, 4, 7, 0.17);
  padding-top: 15px;
  padding-right: 5px;
}

.top-div-container .top-header .question-content {
  z-index: 1;
  width: 10%;
  height: 70%;
  padding-top: 15px;
  padding-right: 5px;
}

.top-div-container .top-descrp {
  background-color: var(--color-white);
  height: 26px;
  padding: 15px 35px;
  box-shadow: 0 4px 6px -2px rgba(0, 4, 7, 0.17);
  display: flex;
  justify-content: space-between;
}

.top-div-container .top-descrp .multi-tab {
  display: flex;
}

.top-div-container .top-descrp .multi-tab .not-active {
  padding: 5px 25px 20px 25px;
  text-decoration: none;
  color: var(--color-black);
}

.top-div-container .top-descrp .multi-tab .active {
  height: 20px;
  min-width: 150px;
  padding: 5px 20px;
  text-decoration: none;
  background-color: #151516;
  color: var(--color-white);
  border-radius: 25px;
  text-align: center;
}

.top-div-container .top-descrp .page-title {
  height: 20px;
  padding: 5px 20px;
  background-color: #151516;
  border-radius: 25px;
  text-align: center;
}

.new-oppty-link {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.new-oppty-btn {
  margin-right: 20px !important;
  background-color: whitesmoke !important;
  width: 170px !important ;
  font-weight: bolder !important;
  border-radius: 20px !important;
  min-width: 165px !important;
}

.new-oppty-btn:hover {
  background-color: rgb(216, 213, 213) !important;
}

.new-oppty-link:hover {
  color: rgb(42, 42, 42);
}

.new-oppty-arrow {
  margin-left: 4px;
  font-size: 19px;
}
