.formRow {
  display: grid;
  grid-template-columns: 250px 250px auto;
  column-gap: 25px;
  align-items: start;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputFlex {
  display: flex;
  align-items: center;
}

.inputFlexDiv {
  margin-right: 2rem;
}

.semiGroupDiv {
  margin-top: 25px;
}

.webhookLabel {
  color: var(--color-black);
  font-family: ProximaNova;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  font-weight: 700;
}

.formTitle {
  color: #000000;
  font-family: AvenirNext;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
}

.formSubtitle {
  color: var(--color-gray);
  font-family: ProximaNova;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin-top: 12px;
}

.submitForm {
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
}
