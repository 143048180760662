.addMerchantSubtitle .name {
  font-weight: 800;
  padding-right: 15px;
  margin-right: 20px;
  line-height: 25px;
}

.addMerchantSubtitle {
  background-color: white;
  color: var(--color-primary);
  padding: 15px 39px;
  margin-top: 0px;
  display: grid;
  grid-template-columns: 40px 2fr;
}

.nextStepBtn:disabled {
  opacity: 0.5469889;
}

.nextbtnContainer {
  width: 20%;
}

.nextStepBtn {
  color: var(--color-white);
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  min-height: 40px;
  width: 80%;
  font-size: 13px;
  /* grid-column: 5; */
  margin-right: 0.1%;
  border-radius: 10px;
  cursor: pointer;
}

.providerContainer {
  margin: 28px 40px;
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  width: calc(100% - 130px);
  padding: 18px 26px;
}
.headerContainer {
  position: -webkit-sticky; /* for safari */
  position: sticky;
  top: 0;
  /* display: grid; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* grid-template-columns: repeat(auto-fill, minmax(200px, 220px)); see notes below */
  /* grid-gap: 1em; */
  background-color: var(--color-white);
  padding: 15px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.057);
  z-index: 2;
}

.headerFilterContainer {
  width: 90%;
  display: flex;
  justify-content: flex-start;
}
.containerTitle {
  color: var(--color-primary);
  font-family: ProximaNova;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  font-weight: 700;
  display: flex;
}

.containerTitleSubMessage {
  color: var(--color-primary);
  font-family: ProximaNova;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  /* font-weight: 700; */
  display: flex;
}

.providersList {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  margin-top: 20px;
  z-index: 1;
}
.providersList li {
  display: grid;
  margin: 20px 10px;
  align-items: center;
  border: 1px solid #e2e7e8;
  border-radius: 10px;
  padding: 20px 10px;
}

.providersList .selectedProvider {
  border: 1px solid var(--color-primary);
  border-radius: 10px;
}

.provider {
  width: 150px;
  vertical-align: middle;
}
.filteredProvider {
  width: 150px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  vertical-align: middle;
  opacity: 0.5;
}
.providersListWithButton {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.actionBtn {
  display: flex;
  justify-content: flex-end;
}
.providerButton {
  background: transparent;
  cursor: pointer;
  border: none;
  display: block;
  outline: none;
}
.backArrow {
  color: var(--color-primary);
  text-decoration: none;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.disclamerMsg {
  color: var(--color-primary);
  font-family: AvenirNext;
  font-size: 12px;
  line-height: 1.2;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  padding-top: 10px;
  display: block;
}
.bnplCurrencies {
  color: var(--color-primary);
  font-family: AvenirNext;
  font-size: 12px;
  line-height: 1.2;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding-left: 5px;
  margin-top: -10px;
  position: absolute;
  font-weight: 600;
  font-size: 12px;
}
.backArrow .bnplCurrencies {
  color: var(--color-primary);
  font-family: AvenirNext;
  font-size: 12px;
  line-height: 1.2;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding-left: 5px;
  position: relative;
  font-weight: 600;
  font-size: 12px;
}

.MuiSvgIcon-root {
  vertical-align: middle;
}
.manageMerchantBackNav {
  background-color: #ffffff;
  display: flex;
  padding: 1em;
  margin-bottom: 1em;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 40px;
}

.selectionPane {
  background-color: #ffffff;
  border-radius: 1px;
  box-shadow: 2px 0 8px 0 rgba(0, 0, 0, 0.15);
  padding: 0px 40px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid var(--color-border-light-gray);
}

.selectionNav button {
  border-right: 1px solid #dde2eb;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
  color: var(--color-black);
  font-family: ProximaNova;
  font-size: 14px;
  line-height: 17px;
  background: transparent;
  padding: 12px 20px;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
  display: inline-block;
}

.selectionNav {
  overflow: auto;
  white-space: nowrap;
}

.selectionNav button:hover,
button.activeButton {
  color: var(--color-white);
  background-color: var(--color-black);
  opacity: 1;
}

.contentContainer {
  margin: 0 40px;
}

.merchantModalSubtitle {
  color: var(--color-gray);
  font-family: ProximaNova;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 36px;
}
.merchantModalTitle {
  color: var(--color-primary);
  font-family: ProximaNova;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
}
.merchantModalButtons {
  display: flex;
  justify-content: space-between;
}

.selectProviderAction {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  align-items: normal;
}

.rules-merchant-form-select {
  width: 214px;
  background-color: var(--color-white);
  border: 1.5px solid rgba(2, 2, 2, 1);
  border-radius: 5px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.02), 0 1px 3px 0 rgba(50, 50, 93, 0.15);
  height: 36px;
  padding-left: 30px;
  color: var(--color-black);
  font-family: ProximaNova;
  font-size: 15px;
  line-height: 18px;
  opacity: 0.5029529389880952;
  display: block;
}

.headerFilterContainer .input-wrapper {
  background-color: white;
  border-radius: 10px;
  font-family: ProximaNova;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: 0 5px;
  border: 1px solid rgba(2, 2, 2, 1);
  box-shadow: 1px 2px 8px #ddd;
  margin-right: 1%;
}

.headerFilterContainer .select-box {
  background-color: transparent;
  background: transparent;
  outline: none;
  margin-left: 10px;
  width: 70%;
  border: none;
  box-shadow: none;
  /* max-width: 170px !important; */
  font-family: 'Aeonik-Regular', Helvetica !important;
}

.providerContainer .select-box .MuiSelect-selectMenu:focus {
  background: transparent;
}

#menu-customized-filter .MuiMenuItem-root {
  width: 13.5rem;
  white-space: pre-wrap;
}

#menu-customized-filter .MuiPopover-paper {
  margin-left: -45px !important;
  max-height: 500px !important;
}

.headerFilterContainer .search-box {
  border: none;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  margin-left: 10px;
}

.search-box::placeholder {
  color: #000000;
  opacity: 1;
}
.providerContainer .MuiCheckbox-colorSecondary.Mui-checked {
  color: #0066ff !important;
}

.providersList .no-record-found {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-icon {
  width: 24px;
  height: 24px;
  display: block;
  justify-content: flex-start;
}

.verticalline {
  border-left: 1px solid #dde2eb;
  height: 40px;
  display: inline-block;
}
