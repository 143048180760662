.brand-area {
  display: flex;
  vertical-align: middle;
  height: 90px;
}

.brand-area .brand-logo {
  max-height: 90px;
  display: block;
  margin: auto;
}

.brand-area .brand-sub-logo {
  max-width: 100%;
}

.nav-links {
  display: block;
}

.link-icon {
  max-height: 30px;
  max-width: 25px;
  padding-right: 7px;
}

.diff-size-icons {
  max-height: 30px;
  max-width: 25px;
  margin-left: 5px;
  padding-left: 0px;
}

.nav-links .menu-link {
  display: block;
  color: var(--color-left-sidebar-text);
  padding: 14px 0 14px 20px;
  height: 21px;
  font-size: 12px;
  text-decoration: none;
}

.m-link {
  color: var(--color-left-sidebar-text);
}

.nav-links .menu-link span {
  position: absolute;
  padding-left: 5px;
  padding-top: 5px;
  vertical-align: middle;
}

/* Active/current link */
.nav-links .menu-link.active {
  background-color: var(--color-prod);
  color: var(--color-left-sidebar-text);
}

.nav-links .menu-link.theme-active {
  background-color: var(--color-left-sidebar-active);
  color: var(--color-left-sidebar-text);
}

.nav-links .menu-link.sandbox-active {
  background-color: var(--color-prod);
  color: var(--color-left-sidebar-text);
}

.nav-links .menu-link.prod-active {
  background-color: var(--color-sandbox);
  color: var(--color-left-sidebar-text);
}

.nav-report.m-link .active .MuiListItem-root.nested {
  background-color: var(--color-left-sidebar-active);
  color: var(--color-left-sidebar-text);
}

.nav-report .sandbox-active {
  background-color: var(--color-prod);
  color: var(--color-left-sidebar-text);
}

.nav-report .prod-active {
  background-color: var(--color-sandbox);
  color: var(--color-left-sidebar-text);
}

/* Links on mouse-over */
.nav-links .menu-link:hover:not(.active) {
  background-color: var(--color-left-sidebar-hover);
  color: var(--color-left-sidebar-text);
}

.MuiSvgIcon-root {
  vertical-align: middle;
}
.nested {
  padding-left: 6px;
}

.nav-report {
  text-decoration: none;
}

.dropdown-nav {
  color: white;
  text-decoration: none;
}

.report-text span {
  font-size: 12px !important;
}

.report-icon {
  min-width: 42px !important;
}

.dropodown-report-icon {
  margin-left: 20px;
  color: white;
  font-size: small !important;
}

.nav-list {
  padding-bottom: 0px !important;
}

@media only screen and (max-width: 1400px) {
  .nav-links .menu-link span {
    margin-right: 22px;
  }
}
.collapse-option {
  padding-top: 0px;
  padding-bottom: 0px;
}

.optty-direct-text span {
  font-size: 12px !important;
}

.optty-direct-icon {
  min-width: 29px !important;
}

.optty-direct-dropdown-icon {
  margin-left: 20px;
  color: white;
  font-size: small !important;
}
