.shadowed-container {
  padding: 30px 30px;
  height: auto;
  background-color: var(--color-white);
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}

.api-credentials-link {
  color: var(--color-primary);
  font-style: italic;
  margin: 10px 0px 15px 0px;
  font-size: 13px;
}

.api-credentials-link a {
  text-decoration: none;
}

.api-credentials-link a:visited {
  color: var(--color-primary);
}

.credential-container {
  flex: 1;
  margin-bottom: 25px;
}

.credential-container form {
  margin-top: 32px;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-bottom: 25px;
}

.form-container {
  margin: 28px 40px;
  background-color: var(--color-white);
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  width: 1148px;
  height: 465px;
  padding: 18px 26px;
}
.form-title {
  color: var(--color-primary);
  font-family: ProximaNova;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  font-weight: 700;
}
.form-subtitle {
  color: var(--color-primary);
  font-family: ProximaNova;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin-top: 12px;
}
.form-img {
  margin-top: 30px;
  width: 173px;
  margin-bottom: 30px;
}
.form-manage-img {
  margin-top: 7px;
  width: 173px;
  margin-bottom: 30px;
}
.form-submit {
  margin-top: 40px;
  display: flex;
}
.manage-form-submit {
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
}
.form-error {
  color: var(--color-danger);
  font-family: ProximaNova;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  margin-top: 15px;
}
.active-notice {
  color: var(--color-gray);
  font-family: ProximaNova;
  font-size: 14px;
  margin-bottom: 10px;
}
.merchant-modal-subtitle {
  color: var(--color-gray);
  font-family: ProximaNova;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 36px;
}
.merchant-modal-title {
  color: var(--color-primary);
  font-family: ProximaNova;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
}

.merchant-modal-buttons {
  display: flex;
  justify-content: space-between;
}

.MuiSwitch-track {
  background-color: var(--color-gray) !important;
}
