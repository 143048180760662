.profile-icon {
  color: var(--color-primary);
  font-size: medium;
  min-width: 30px !important;
}

.dropdown {
  position: relative;
  align-items: center;
}

.dropdown-btn:hover {
  cursor: pointer;
}

.dropdown-btn {
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
}

.dropdown-btn .greetings {
  margin: auto;
  display: inline-block;
  width: 85%;
  font-size: 13px;
}

.dropdown-btn .greetings strong {
  font-size: 13px;
}

.dropdown-btn .arrow-dropdown {
  margin: auto;
  width: 15%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--color-white);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  width: 200px;
  z-index: 3;
}

.dropdown:hover .dropdown-content {
  display: block;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: absolute;
  z-index: 3;
}

.dropdown:hover .dropbtn {
  background-color: var(--color-white);
  cursor: pointer;
}

.dropdown:hover .dropdown-content .d-link {
  text-decoration: none;
  color: var(--color-primary);
  font-weight: 700;
  font-size: 11px;
  display: block;
  display: flex;
  padding: 10px;
}

.dropdown:hover .dropdown-content .d-link {
  border-bottom: 1px solid #f7f7f7;
}

.dropdown:hover .dropdown-content .d-link span {
  margin: auto;
  margin-left: 5px;
  width: 150px;
}
