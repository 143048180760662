/* MultiSelect.css */
.multi-select {
  position: relative;
  display: inline-block;
  width: 250px;
}

.multi-select-header {
  padding: 8px;
  border: 1px solid var(--color-primary) !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-button {
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 16px;
}

.multi-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  color: var(--color-primary) !important;
  background-color: var(--color-white) !important;
  border: 1px solid #ccc;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.option {
  position: relative;
  padding: 8px;
  cursor: pointer;
}

.option:hover {
  color: var(--color-white) !important;
  background-color: var(--color-primary) !important;
}

.option.selected {
  border: 1px solid #ccc;
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}

.option.selected::after {
  content: '✔'; /* Unicode tick mark */
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust as needed */
  transform: translateY(-50%);
  font-size: 16px; /* Adjust size as needed */
  color: var(--color-white) !important;
}

.option::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none; /* Prevents ripple from blocking clicks */
}

.option:hover::before {
  transform: scale(2.5);
  opacity: 0;
}
