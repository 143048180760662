.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  margin: 22px 0 60px 0;
}
.panel {
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}
.header {
  padding: 11px 18px;
  color: #ffffff;
  font-family: ProximaNova;
  font-size: 16px;
  line-height: 19px;
  background-color: #000000;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}
.content {
  padding: 20px 30px;
}
.formTitle {
  color: #000000;
  font-family: AvenirNext;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
}
.titleBorder {
  margin: 15px 0;
  border: 1px solid #cacaca;
}
.subTitle {
  font-weight: 500;
  color: #8d9398;
  font-family: AvenirNext;
  font-size: 12px;
  line-height: 14px;
}
.editorTitle {
  margin: 50px 0 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.editorTitleBold {
  color: #000000;
  font-family: ProximaNova;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  font-weight: 600;
  margin-right: 4px;
}
.editorTitleFaded {
  color: #8d9398;
  font-family: ProximaNova;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  margin-right: 4px;
}
.editorTitleTag {
  color: #40ca64;
  font-family: ProximaNova;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  margin-right: 16px;
}
.editorTitleLink {
  background: transparent;
  border: none;
  color: #000000;
  font-family: ProximaNova;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  font-weight: 600;
  text-decoration: underline;
}
.editorLearnMoreCircle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #40ca64;
  color: #ffffff;
  display: inline-grid;
  place-items: center;
  margin-right: 4px;
  text-align: center;
  font-size: 13px;
}
.editorLearnMoreText {
  color: #40ca64;
  font-family: ProximaNova;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  font-weight: 600;
}
.editorClass {
  background-color: #ffffff;
  border: 1px solid #ececec;
  min-height: 246px;
  height: auto;
  padding: 0 16px;
}
.editorToolbar {
  margin-top: 1em;
}
.titleMargin {
  margin-top: 50px;
}
.actionButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}
.formRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.formControl {
  margin-top: 22px;
}
.colorControl {
  width: 100%;
  background-color: #ffffff;
  border: 1.5px solid #f2f2f2;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.02), 0 1px 3px 0 rgba(50, 50, 93, 0.15);
  width: 214px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.colorBox {
  border: 1px solid #f2f2f2;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.02), 0 1px 3px 0 rgba(50, 50, 93, 0.15);
  width: 20px;
  height: 20px;
  margin-left: 20px;
  margin-right: 14px;
}
.parentFlex {
  display: flex;
  align-items: center;
}
.colorLens {
  margin-right: 11px;
}
.colorText {
  pointer-events: none;
}
.topMargin {
  margin-top: 20px;
}
.editorTitleLink {
  cursor: pointer;
}
.tagsModalTitle {
  color: #000000;
  font-family: ProximaNova;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}
.tagsModalFooter {
  display: flex;
  justify-content: flex-end;
}
.tableHeaderGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  padding: 7px 0;
  border-bottom: 1px solid #ececec;
  color: #8d9398;
  font-family: ProximaNova;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
}
.tableBodyGrid {
  margin-top: 12px;
}
.tableRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  margin-top: 16px;
}
.tableLabel {
  color: #8d9398;
  font-family: ProximaNova;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}
.tableTag {
  color: #40ca64;
  font-family: ProximaNova;
  font-size: 12px;
  line-height: 14px;
}
.tableValue {
  font-weight: 600;
  color: #000000;
  font-family: ProximaNova;
  font-size: 15px;
  line-height: 18px;
}
.colorPickerFooter {
  width: 80%;
  padding: 10px;
  box-sizing: initial;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
}
.bnplStar {
  width: 18px;
  height: 17px;
  vertical-align: top;
}
.preWidgetContainer {
  display: flex;
  height: 20px;
  align-items: center;
  padding: 0 7px;
  align-items: center;
  background-color: #ffffff;
}
.circle {
  width: 10px;
  height: 10px;
  background: transparent;
  border-radius: 50%;
  border: 1px solid grey;
}
.preWidgetIcon {
  margin-left: auto;
}
.preWidgetLogo {
  height: 30px;
  width: auto;
  margin-left: 5px;
}
.paneHeaderPreview {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.previewTitle {
  color: #ffffff;
  font-family: ProximaNova;
  font-size: 13px;
  line-height: 16px;
  margin-right: 10px;
}
