.profile-icon {
  color: var(--color-primary);
  font-size: medium;
  min-width: 36px !important;
}

.profile-dropdown {
  width: 100%;
}

.dropdown {
  position: relative;
  align-items: center;
}

.dropdown-btn:hover {
  cursor: pointer;
}

.dropdown-btn {
  display: flex;
  margin-left: 5px;
  align-items: center;
}

.dropdown-btn .greetings {
  text-align: left;
  padding-left: 2px;
  padding-right: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px !important;
  text-wrap: nowrap;
}

.dropdown-btn .greetings strong {
  font-size: 13px;
}

.dropdown-btn .arrow-dropdown {
  margin: auto;
  width: 10px !important;
  min-width: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.profile-dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--color-white);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #00000026;
  width: 100% !important;
  z-index: 3;
}

.dropdown:hover .profile-dropdown-content {
  display: block;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: absolute;
  z-index: 3;
}

.dropdown:hover .dropbtn {
  background-color: var(--color-white);
  cursor: pointer;
}

.dropdown:hover .profile-dropdown-content .d-link {
  text-decoration: none;
  color: var(--color-primary);
  font-weight: 700;
  font-size: 11px;
  display: block;
  display: flex;
  padding: 10px;
  cursor: pointer;
}

.dropdown:hover .profile-dropdown-content .d-link {
  border-bottom: 1px solid #f7f7f7;
}

.dropdown:hover .profile-dropdown-content .d-link span {
  margin: auto;
  margin-left: 5px;
  width: 150px;
}
