:root {
  --primary: #4eaabc;
  --color-white: #ffffff;
  --color-error: #eb333e;
}

.OAuthPaymentConnect {
  height: 50px;
  text-align: center;
  text-transform: capitalize !important;
  background: var(--primary) !important;
  color: var(--color-white) !important;
  border: 1px solid var(--primary) !important;
  border-radius: 6px !important;

  .MuiButton-label {
    align-items: inherit !important;
  }
}

.OAuthPaymentDisconnect {
  height: 50px;
  border: 1px solid var(--color-error) !important;
  color: var(--color-error) !important;
  padding: 10px 34px !important;
  text-align: center;
  text-transform: capitalize !important;
}

.verify-circle {
  background-color: var(--primary) !important;
  color: var(--color-white) !important;
  height: 14% !important;
  width: 5% !important;
  border-radius: 33px !important;
}

#merchantOAuthDisconfigPayment {
  height: 160px;
  min-height: 160px;
}

#merchantOAuthConfigPayment {
  height: 160px;
  min-height: 160px;
  display: flex;
  justify-content: center;
}

.confirmMSG {
  margin-bottom: 30px !important;
  text-align: center;
}

.configuration {
  margin-top: 5px !important;
}
