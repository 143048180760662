.form-title {
  color: var(--color-primary);
  font-family: ProximaNova;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  font-weight: 700;
}
.form-subtitle {
  color: var(--color-gray);
  font-family: ProximaNova;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin-top: 12px;
}
.rule-form-container {
  margin-top: 57px;
  width: initial;
}
.rules-form-row {
  display: grid;
  grid-template-columns: 250px 250px auto;
  column-gap: 25px;
  align-items: start;
  margin-top: 20px;
}
.rules-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
span.Mui-checked > span.MuiIconButton-label > span.MuiSwitch-thumb {
  background-color: var(--color-primary);
}
span:not(.Mui-checked) > span.MuiIconButton-label > span.MuiSwitch-thumb {
  background-color: var(--color-primary);
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: rgba(0, 0, 0, 0.4);
}
.MuiSwitch-track {
  background-color: var(--color-light-green) !important;
}
.values-flex {
  display: flex;
  align-items: center;
  gap: 16px;
  grid-column: span 2;
  height: 100%;
}
.values-flex > .tooltip {
  margin-top: 0;
}
.values-flex input {
  width: 214px;
}
.group-div {
  margin-top: 40px;
}
.semi-group-div {
  margin-top: 22px;
}
.rules-label {
  color: var(--color-black);
  font-family: ProximaNova;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
}

.tooltip {
  margin-left: 12px;
  background: var(--color-primary);
  color: var(--color-white);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.tooltip-disabled {
  background: var(--color-gray);
  color: var(--color-white);
}
.rules-merchant-form-select {
  width: 214px;
  background-color: var(--color-white);
  border: 1.5px solid var(--color-border-gray);
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.02), 0 1px 3px 0 rgba(50, 50, 93, 0.15);
  height: 36px;
  padding-left: 30px;
  color: var(--color-black);
  font-family: ProximaNova;
  font-size: 15px;
  line-height: 18px;
  opacity: 0.5029529389880952;
  display: block;
}
.rules-comment {
  align-self: center;
}

.checkbox {
  align-items: start;
  margin-top: 20px;
}

/* Needed to override the default style provided by MUI */
.checkbox > span {
  font-size: 13px;
  max-width: 325px;
}

.rules-form-row .customTextColumn {
  margin-top: 0;
}

.rules-form-row .customTextColumn > label {
  text-transform: none;
}

.rules-form-row .input-box ::placeholder {
  padding-left: 5px;
}

.rules-form-row .input-box input {
  padding-left: 42px;
}

.rules-form-row .input-box input:disabled {
  cursor: auto;
}

.update-checkout-value {
  margin-top: 1.5rem;
}
.provider-fees-div,
.customer-fees-div {
  display: flex;
  column-gap: 60px;
  align-items: flex-start;
  margin-top: 20px;
}

.customer-fees-div {
  margin-top: 0;
  margin-bottom: 20px;
}
