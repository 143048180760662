.pageTitle {
  color: var(--color-primary);
  font-family: ProximaNova;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  font-weight: 700;
}

.pageSubTitle {
  color: var(--color-gray);
  font-family: ProximaNova;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  margin: 22px 0 60px 0;
}
.panel {
  display: grid;
  grid-row-gap: 16px;
}

.formTitle {
  color: #000000;
  font-family: AvenirNext;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
}

.titleBorder {
  border: 1px solid #cacaca;
}

.subTitle {
  font-weight: 500;
  color: #8d9398;
  font-family: AvenirNext;
  font-size: 12px;
  line-height: 14px;
}

.editorTitle {
  margin: 50px 0 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.editorTitleBold {
  color: #000000;
  font-family: ProximaNova;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  font-weight: 600;
  margin-right: 4px;
}

.editorTitleFaded {
  color: #8d9398;
  font-family: ProximaNova;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  margin-right: 4px;
}

.editorTitleTag {
  color: #40ca64;
  font-family: ProximaNova;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  margin-right: 16px;
}

.editorTitleLink {
  background: transparent;
  border: none;
  color: #000000;
  font-family: ProximaNova;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  font-weight: 600;
  text-decoration: underline;
}

.editorLearnMoreCircle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #40ca64;
  color: #ffffff;
  display: inline-grid;
  place-items: center;
  margin-right: 4px;
  text-align: center;
  font-size: 13px;
}

.editorLearnMoreText {
  color: #40ca64;
  font-family: ProximaNova;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  font-weight: 600;
}

.editorClass {
  background-color: #ffffff;
  border: 1px solid #ececec;
  min-height: 246px;
  height: auto;
  padding: 0 16px;
}

.editorToolbar {
  margin-top: 1em;
}

.editorTitleLink {
  cursor: pointer;
}
.tagsModalTitle {
  color: #000000;
  font-family: ProximaNova;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}
.tagsModalFooter {
  display: flex;
  justify-content: flex-end;
}
.tableHeaderGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  padding: 7px 0;
  border-bottom: 1px solid #ececec;
  color: #8d9398;
  font-family: ProximaNova;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
}
.tableBodyGrid {
  margin-top: 12px;
}
.tableRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  margin-top: 16px;
}
.tableLabel {
  color: #8d9398;
  font-family: ProximaNova;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}
.tableTag {
  color: #40ca64;
  font-family: ProximaNova;
  font-size: 12px;
  line-height: 14px;
}
.tableValue {
  font-weight: 600;
  color: #000000;
  font-family: ProximaNova;
  font-size: 15px;
  line-height: 18px;
}
.tableLabel {
  color: #8d9398;
  font-family: ProximaNova;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}
