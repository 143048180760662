.blank-main-content {
  display: flex;
  background-color: var(--main-page-color);
}

.blank-sidebar-content {
  background-color: var(--color-white);
  border-top-right-radius: 10px;
  box-shadow: 0 5px 14px 0 rgba(0, 4, 7, 0.17);
  height: 100vh;
  overflow: hidden;
}

.blank-sidebar-wraper {
  width: 15%;
  height: 100vh;
  position: fixed;
}

.blank-dashboard-content {
  width: 85%;
  margin-left: 15%;
}

.blank-top-header {
  display: flex;
  height: 78px;
  background-color: var(--color-white);
  border-bottom: 1px solid rgba(206, 211, 219, 0.17);
}

.blank-top-descrp {
  background-color: #e0e2ea;
  height: 26px;
  padding: 15px 0 15px 35px;
  box-shadow: 0 4px 6px -2px rgba(0, 4, 7, 0.17);
  display: flex;
  justify-content: space-between;
}

.blank-page-wrapper {
  padding: 48px;
  display: flex;
  flex-direction: column;
}

.blank-page-wrapper div {
  margin-top: 10px;
  margin-bottom: 10px;
}

.blank-page-title {
  background-color: #e0e2ea;
  width: 444px;
  height: 53px;
}

.blank-pebble-wrapper {
  display: flex;
  justify-content: space-between;
}

.pebble-1 {
  background-color: #e0e2ea;
  border-radius: 13.5px;
  width: 44px;
  height: 22px;
}

.pebble-2 {
  background-color: #e0e2ea;
  border-radius: 2px;
  width: 103px;
  height: 16px;
}

.pebble-3 {
  background-color: #e0e2ea;
  border-radius: 2px;
  width: 87px;
  height: 16px;
}

.pebble-4 {
  background-color: #e0e2ea;
  border-radius: 2px;
  width: 118px;
  height: 16px;
}

.pebble-5 {
  background-color: #e0e2ea;
  border-radius: 2px;
  width: 85px;
  height: 16px;
}

.pebble-6 {
  background-color: #e0e2ea;
  border-radius: 2px;
  width: 118px;
  height: 16px;
}

.pebble-7 {
  background-color: #e0e2ea;
  border-radius: 2px;
  width: 50px;
  height: 16px;
}

.pebble-8 {
  background-color: #e0e2ea;
  border-radius: 2px;
  width: 67px;
  height: 16px;
}

.pebble-9 {
  background-color: #e0e2ea;
  border-radius: 2px;
  width: 67px;
  height: 16px;
}

.blank-rectangle-1 {
  background-color: #e0e2ea;
  border-radius: 2px;
  width: 100%;
  height: 348px;
}

.blank-rectangle-2 {
  background-color: #e0e2ea;
  border-radius: 2px;
  width: 100%;
  height: 88px;
}
